import CoordinatorRepository from '@/shared/http/repositories/socialProject/coordinator'
import ModalityRepository from '@/shared/http/repositories/socialProject/modality'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import AccountRepository from '@/shared/http/repositories/access/account'
import BreadCrumb from "@/components/layout/BreadCrumb/breadCrumb.vue"
import GroupRepository from '@/shared/http/repositories/access/group'
import { loading, toast } from '@/shared/utils/UIHelper'
import Coordinator from '@/shared/models/coordinator'
import { IsSuccessCode } from '@/shared/utils/API'
import Account from '@/shared/models/account'
import Roles from '@/shared/mixins/hasRoles'
import GuidUtils from '@/shared/utils/guid'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb
  },

  mixins: [Roles],

  data: () => ({
    account: new Account(),
    coordinator: new Coordinator(),
    enableInputTeacher: true,
    disableInputName: false,
    isTeacher: false,
    showModalActiveAccount: false,
    isEditable: false,
    isNew: null,
    showModalReloadTeachers: false,
    teachersWithoutAccount: [],
    modalities: [],
    groups: [],
    accounts: [],
    categories: [],
    teacherGroup: '6b1583ea-38ae-42f4-a881-f05d6ff7c0f1',
    coordinatorGroup: 'bd4a77f0-e318-405e-3952-08da4e268814',
    isGroupCoordinator: false,
    disableInputAccount: false,
    hasCoordinatorId: false
  }),

  created() {
    this.$validator.reset()

    this.isNew = !this.$route.params.id || GuidUtils.isEmpty(this.$route.params.id)
    if(!this.isNew) {
      this.getAccountById()
    }
    this.getGroups()
  },

  computed: {
    checkTeacherName () {
      if (this.account.teacherID) {
        const teacher = this.teachersWithoutAccount.find(element => element.id == this.account.teacherID)

        if (teacher) this.account.name = teacher.name

        this.isTeacher = true
        this.disableInputName = true
        return
      }
      this.disableInputName = false
      this.isTeacher = false
      return
    },

    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: "Dashboard",
            href: this.$router.resolve({ name: "Dashboard" }).href
          },
          {
            text: "Listagem de Contas",
            href: this.$router.resolve({ name: "Account" }).href
          },
          {
            text: "Gerenciar Contas",
          }
        ]
      };
    },

    enableSaveButton() {
      if (this.account.status !== 0) {
        return false
      }
      return true
    }
  },

  watch: {
    'coordinator.modalityId': {
      handler: function (value) {
        if (value && !this.isNew && !this.hasCoordinatorId) {
          this.coordinator.categoryIds = []
          return
        } else if (!this.hasCoordinatorId) {
          this.coordinator.categoryIds = []
        }
      }
    },

    'account.groupID': {
      handler: function (value) {
        const isGroupCoordinator = value == this.coordinatorGroup
        if (isGroupCoordinator) {
          this.isGroupCoordinator = true
          
          if (this.isNew) {
            this.disableInputAccount = false

          } else {
            const accountId = this.$route.params.id
            this.coordinator.accountId = accountId
            this.disableInputAccount = true
            
            this.isCoordinatorMethod(accountId)
          }

          this.getAllAccounts()
          this.getAllModalities()
          return
        }
        //--> Se não for do grupo coordenador
        this.isGroupCoordinator = false
        this.clearCoordinatorInputs()
      }
    }
  },

  methods: {
    async isCoordinatorMethod(accountId) {
      await this.getCoordinatorByAccountId(accountId)
      await this.getAllCategories()
    },

    clearCoordinatorInputs() {
      this.coordinator = new Coordinator()
      this.categories = []
      this.disableInputAccount = false
    },

    getAllTeachers () {
      loading.push()
      TeacherRepository.GetAllWithoutAccount()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.teachersWithoutAccount = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    async getCoordinatorByAccountId (accountId) {
      loading.push()
      await CoordinatorRepository.GetByAccountId(accountId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const response = res.data.data
          this.hasCoordinatorId = false

          if (response) {
            this.hasCoordinatorId = response.ids.some(x => x.length > 0)
            this.coordinator.modalityId = response.modalityId
            this.coordinator.categoryIds = response.categoryIds
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar conta', 'ERRO')
        })
    },

    onSaveCoordinator () {
      this.coordinator.groupId = this.account.groupID
      loading.push()
      CoordinatorRepository.Create(this.coordinator)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar coordenador', 'ERRO')
        })
    },

    onDeleteCoordinator() {
      loading.push()
      CoordinatorRepository.Delete(this.coordinator.accountId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Sucesso. Esta conta não é mais Coordenador', 'DESATRELAR COORDENADOR')
          this.clearCoordinatorInputs()
          this.hasCoordinatorId = false
          this.isGroupCoordinator = true
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao desatrelar coordenador', 'ERRO')
        })
    },

    onUpdateCoordinator () {
      this.coordinator.groupId = this.account.groupID
      loading.push()
      CoordinatorRepository.Update(this.coordinator)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao alterar coordenador', 'ERRO')
        })
    },

    getAllModalities () {
      loading.push()
      ModalityRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalities = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar modalidades', 'ERRO')
        })
    },

     async getAllCategories() {
      await CategoryRepository.GetAllByModality(this.coordinator.modalityId)
       .then(response => {
        this.categories = response.data.data
      })
      .catch(() => {
        this.$refs.snackBar.ShowError('Não foi possível carregar a lista de Categorias.')
      })
    },

    getAllAccounts () {
      loading.push()
      AccountRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.accounts = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar as contas', 'ERRO')
        })
    },

    reloadTeachers() {
      this.teachersWithoutAccount = []
      this.account.teacherID = null
      this.getAllTeachers()
      this.showModalReloadTeachers = false
    },

    getGroups () {
      loading.push()
      GroupRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.groups = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar grupos', 'ERRO')
        })
    },

    getAccountById() {
      loading.push()
      AccountRepository.GetByID(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.account = new Account(res.data.data)

          if (this.account.groupID == this.teacherGroup) {
            this.getTeacherByAccount()
            this.isEditable = true
          }
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar conta', 'ERRO')
        })
    },

    getTeacherByAccount() {
      loading.push()
      TeacherRepository.GetTeacherByAccountID(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
         
          if (res.data.data) {
            this.account.teacherID = res.data.data.id
            const teacher = [{id: res.data.data.id, name: res.data.data.name}]
            this.teachersWithoutAccount = teacher
            this.enableInputTeacher = false
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professor pelo id da conta', 'ERRO')
        })
    },

    saveOrEditAccount() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
          return
        }

        
        if (this.isNew) {
          if (this.isGroupCoordinator) {
            if (!this.coordinator.modalityId || this.coordinator.categoryIds.length == 0) {
              toast.info('É necessário preencher os campos', 'COORDENAÇÃO')
              return
            }
            this.onSaveCoordinator()
            this.clearCoordinatorInputs()
          }

          this.onSave()
          return
        }

        if (this.isGroupCoordinator) {
          if (!this.coordinator.modalityId || this.coordinator.categoryIds.length == 0) {
            toast.info('É necessário preencher os campos', 'COORDENAÇÃO')
            return
          }
          this.onUpdateCoordinator()
          this.clearCoordinatorInputs()
        }
        this.onUpdate()
      })
    },

    onSave() {
      loading.push()
      this.account.name = this.account.name.toUpperCase()
      AccountRepository.Create(this.account)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.account.id = res.data.data.id

          if (this.isTeacher) {
            TeacherRepository.AssociateAccount(this.account.teacherID, this.account.id)
          }

          toast.success('Conta salva com sucesso', 'SUCESSO')
          this.backTo()
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('E-mail já cadastrado', 'ERRO')
        })
    },

    onUpdate() {
      loading.push()
      this.account.name = this.account.name.toUpperCase()
      AccountRepository.Update(this.account)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (this.isTeacher) {
            TeacherRepository.AssociateAccount(this.account.teacherID, this.account.id)
          }

          toast.success('Conta editada com sucesso', 'SUCESSO')
          this.backTo()
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar uma conta', 'ERRO')
        })
    },

    checkGroup () {
      if (this.account.groupID === this.teacherGroup) {
        this.enableInputTeacher = false
        
        return this.getAllTeachers ()
      }

      this.enableInputTeacher = true
      this.account.teacherID = null
      return
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    showModalActiveButton() {
      this.showModalActiveAccount = true
    },

    reactiveAccount() {
      loading.push()
      AccountRepository.ReactiveAccount(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          this.getAccountById()
          this.showModalActiveAccount = false

          if (this.account.teacherID) {
            TeacherRepository.ActivateTeacher(this.account.teacherID)
          }

          toast.success('Conta reativada com sucesso', 'SUCESSO')
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao reativar conta', 'ERRO')
        })
    },

    backTo() { this.$router.go(-1) }
  }
}