import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'coordinator'

const GetAll = () => SocialProjectManager.get(resourceName)

const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const GetByAccountId = id => SocialProjectManager.get(`${resourceName}/account/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const SendEmailCoordinators = () => SocialProjectManager.get(`${resourceName}/send-email`)

const CoordinatorRepository = {
  GetAll,
  GetById,
  GetByAccountId,
  Create,
  Update,
  Delete,
  SendEmailCoordinators
}

export default CoordinatorRepository
