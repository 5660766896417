export default class Coordinator {
  constructor({
    id,
    accountId,
    modalityId,
    groupId,
    categoryIds    
  } = {}) {
    this.id = id || '00000000-0000-0000-0000-000000000000',
    this.accountId = accountId,
    this.modalityId = modalityId,
    this.groupId = groupId
    this.categoryIds = categoryIds
  }
}
